import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { t } from "i18next";
import { receiveNumberFormatWithMilhar } from "../../../../services/number-format";
import { useEffect, useState } from "react";
import { ScrapDTO } from "../../../scraps/scrap-admin-generator-view/components/scrap-form/models/scrap-DTO";
import { getScrapAdminById } from "../../../scraps/scrap-admin-service";
import {
  getScrapGeneratorById,
  getScrapGeneratorInfo,
} from "../../../scrap-generators/scrap-generator-service";
import {
  ScrapGeneratorDTO,
  ScrapGeneratorInfoDTO,
} from "../../../scrap-generators/scrap-generators-DTO";
import { getScrapDealerById } from "../../../scrap-dealers/scrap-dealers-service";
import { ScrapDealerDTO } from "../../../scrap-dealers/scrap-dealer-DTO";
import s4bLogo from "./../../../../assets/img/SFB_Logo.svg";
import boschLogo from "./../../../../assets/img/Bosch_symbol_logo_black_red.svg";
import { formatTimestampDate } from "../../../../services/date-format";

interface ExtractModalProps {
  item: any;
  onClose: () => void;
}

const ExtractModal: React.FC<ExtractModalProps> = ({ item, onClose }) => {
  const [material, setMaterial] = useState<ScrapDTO | null>();
  const [generator, setGenerator] = useState<ScrapGeneratorInfoDTO | null>();
  const [dealer, setDealer] = useState<ScrapDealerDTO | null>();

  useEffect(() => {
    getScrapAdminById(item.scrapMaterial).then((materialItem) => {
      setMaterial(materialItem);
    });
    getScrapGeneratorInfo(item.generatorId).then((generator) => {
      setGenerator(generator);
    });
    getScrapDealerById(item.contractedDealerId).then((dealer) => {
      setDealer(dealer);
    });
  }, [item]);

  const handleDownloadPDF = async () => {
    const pdfContent = document.querySelector(".pdf-content");

    if (pdfContent) {
      const canvas = await html2canvas(pdfContent as HTMLElement);
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 190;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
      pdf.save(`extract_${item.scrapMaterial}.pdf`);
    } else {
      console.error("PDF content not found");
    }
  };

  return (
    <div className="big-modal-content">
      <div className="flex-column pdf-content" style={{ gap: 10 }}>
        <div
          className="flex-row align-center justify-between"
          style={{ padding: "10px 10px 0px 10px" }}
        >
          <div className="flex-row align-center">
            <img
              src={s4bLogo}
              alt="logo_scrap4better"
              style={{ height: "80px" }}
            />
            <h4 style={{ marginLeft: 10 }}>Bosch Scrap Management Solution</h4>
          </div>
          <img
            src={boschLogo}
            alt="logo_scrap4better"
            style={{ height: "35px" }}
          />
        </div>
        <div className="custom-line-hr"></div>

        <div className="flex-column" style={{ gap: 10 }}>
          <div
            className="flex-column info-section"
            style={{ marginBottom: 22, gap: 10 }}
          >
            <div className="flex-column" style={{ gap: 5 }}>
              <div className="flex-row" style={{ gap: 5 }}>
                <p style={{ fontSize: 20, fontWeight: 600 }}>
                  {t("label-generator")}
                </p>
                {":"}
                <p style={{ fontSize: 20 }}>{generator?.legalName || "N/A"}</p>
              </div>
              <div className="flex-row" style={{ gap: 5 }}>
                <p style={{ fontWeight: 600 }}>
                  {generator?.nationalRegisterLabel ||
                    t("label-national-register")}
                </p>
                {":"}
                <p>{generator?.nationalRegister || "N/A"}</p>
              </div>
            </div>

            <div className="flex-column" style={{ gap: 5 }}>
              <div className="flex-row" style={{ gap: 5 }}>
                <p style={{ fontSize: 20, fontWeight: 600 }}>
                  {t("label-scrap-dealer")}
                </p>
                {":"}
                <p style={{ fontSize: 20 }}>{item.scrapDealerName || "N/A"}</p>
              </div>
              <div className="flex-row" style={{ gap: 5 }}>
                <p style={{ fontWeight: 600 }}>
                  {dealer?.scrapDealerInfo.nationalRegisterLabel ||
                    t("label-national-register")}
                </p>
                {":"}
                <p>{dealer?.scrapDealerInfo.nationalRegister || "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="flex-row justify-between">
            <p>{t("date-collected")}</p>
            <p>{formatTimestampDate(item.dateToCollect) || "--.--.----"}</p>
          </div>
          <div className="flex-row justify-between">
            <p>{t("label-status")}</p>
            <p>{t(`financial-status.${item.status}`) || "N/A"}</p>
          </div>

          <div className="custom-line-hr"></div>

          <div className="flex-row justify-between">
            <p>{t("label-unity-value")}</p>
            <p>
              {item.currency}{" "}
              {material?.scrapContract.contractValueUnity != null &&
              !isNaN(material?.scrapContract.contractValueUnity)
                ? receiveNumberFormatWithMilhar(material?.scrapContract.contractValueUnity)
                : "-"}
            </p>
          </div>

          <div className="flex-row justify-between">
            <p>
              {t("label-quantity")}{" "}
              <strong
                style={{ fontSize: 12, color: "var(--placeholder-text-color)" }}
              >
                ({material?.scrapInfo.unity || t("label-unity")})
              </strong>
            </p>
            <p>{item.quantity || "-"}</p>
          </div>

          <div className="custom-line-hr"></div>

          <div
            className="flex-row justify-between"
            style={{ fontSize: "20px", marginTop: 5 }}
          >
            <p>{t("label-total-value")}</p>
            <p>
              {item.currency}{" "}
              {item.totalValue != null && !isNaN(Number(item.totalValue))
                ? receiveNumberFormatWithMilhar(Number(item.totalValue).toFixed(2))
                : "-"}
            </p>
          </div>

          <div className="flex-row justify-between">
            <p>
              {t("label-fee")}
              {" (FEE)"}
            </p>
            <p>
              {item.currency}{" "}
              {item.valueFee != null && !isNaN(Number(item.valueFee))
                ? receiveNumberFormatWithMilhar(Number(item.valueFee).toFixed(2))
                : "-"}
            </p>
          </div>

          <div className="custom-line-hr"></div>

          <div
            className="flex-row justify-between"
            style={{ fontSize: "20px", marginTop: 5 }}
          >
            <p>{t("label-value-to-receive")}</p>
            <p>
              {item.currency}{" "}
              {item.totalValue != null &&
              item.valueFee != null &&
              !isNaN(Number(item.totalValue - item.valueFee))
                ? receiveNumberFormatWithMilhar(
                    Number(item.totalValue - item.valueFee).toFixed(2)
                  )
                : "-"}
            </p>
          </div>
        </div>
      </div>

      <div style={{ gap: 10, marginTop: 20 }} className="flex-row justify-end">
        <button
          type="button"
          className="neutral-white"
          onClick={() => onClose()}
        >
          {t("label-back")}
        </button>
        <button type="button" className="blue" onClick={handleDownloadPDF}>
          {t("label-pdf-download")}
        </button>
      </div>
    </div>
  );
};

export default ExtractModal;
