import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  SparkIcon,
  SparkTable,
  SparkTableBody,
  SparkTableCell,
  SparkTableHead,
  SparkTableRow,
} from "@bosch-web-dds/spark-ui-react";

import React, { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../../utils/firebase-config";
import ImportExportCsv from "../../../components/ImportExportCsv/ImportExportCsv";
import NoData from "../../../components/NoData/NoData";
import { useAuth } from "../../../contexts/auth-context";
import FilterComponent from "../../../components/FIlter/filter";
import { receiveNumberFormatWithMilhar } from "../../../services/number-format";
import { COLLECTIONS } from "../../../assets/enums/firebase-colections";
import { formatTimestampDate } from "../../../services/date-format";
import Tooltip from "../../../components/tooltip/tooltip";
import ExtractModal from "../components/extract-note-modal/extract-note-modal";
import { profileENUM } from "../../../assets/enums/profiles-enum";

const FinancialToCollectView: React.FC<{ enabledUsers: string[] }> = ({
  enabledUsers,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userDoc } = useAuth();
  const profile = userDoc?.profile;
  const originId = userDoc?.originId;
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any>([]);
  const tableHeader = [
    `${t("label-material")}`,
    `${t("label-scrap-generator")}`,
    `${t("label-scrap-dealer")}`,
    `${t("label-quantity")}`,
    `${t("label-value")}`,
    `${t("label-date")}`,
  ];
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [extractNoteItem, setExtractNoteItem] = useState<any>();
  const [showExtractNoteModal, setShowExtractNoteModal] =
    useState<boolean>(false);

    const handleFilterClick = (filteredData: any[]) => {
      setFilteredData(filteredData);
    };

    
    const sortByname = (data: any[]) => {
      return [...data].sort((a, b) => {
        const nameA = a.scrapMaterialName?.toString().toLowerCase() || "";
        const nameB = b.scrapMaterialName?.toString().toLowerCase() || "";
        return nameA.localeCompare(nameB);
      });
    };
    
  
    const sortByreceptorname = (data: any[]) => {
      return [...data].sort((a, b) => {
        const cityA = a.scrapDealerName?.toString().toLowerCase() || "";
        const cityB = b.scrapDealerName?.toString().toLowerCase() || "";
        return cityA.localeCompare(cityB);
      });
    };
    const sortBygeneratorname = (data: any[]) => {
      return [...data].sort((a, b) => {
        const originA = a.scrapGeneratorName?.toString().toLowerCase() || "";
        const originB = b.scrapGeneratorName?.toString().toLowerCase() || "";
        return originA.localeCompare(originB);
      });
    };
    
  
    const sortOption = [
      { label: t("label-material"), sortFunction: sortByname },
      { label: t("label-scrap-dealer"), sortFunction: sortByreceptorname },
      { label: t("label-scrap-generator"), sortFunction: sortBygeneratorname },
    ];
    const handleSortClick = (sortFunction: (data: any[]) => any[]) => {
      const sortedData = sortFunction([...filteredData]);
      setFilteredData(sortedData);
    };

  const fetchTableData = async () => {
    let q;
    if (profile == profileENUM.ADMIN_BOSCH) {
      q = query(
        collection(firestore, COLLECTIONS.FINANCIAL),
        where("status", "==", "TO_COLLECT")
      );
    } else {
      q = query(
        collection(firestore, COLLECTIONS.FINANCIAL),
        where("status", "==", "TO_COLLECT"),
        where("generatorId", "==", originId)
      );
    }
    const querySnapshot = await getDocs(q);

    const items = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      searchData: doc.data()["scrapDealerName"].concat(
        " ",
        doc.data()["scrapGeneratorName"],
        " ",
          doc.data()["scrapMaterialName"],
        " "
      ),
    })) as unknown as any[];
    setTableData(items);
    setFilteredData(items);
  };
  useEffect(() => {
    fetchTableData();
  }, [id]);

  const handleCloseModal = () => {
    fetchTableData();
  };
  if (enabledUsers.includes(profile || "public")) {
    return (
      <div style={{ marginBottom: "120px" }}>
        {showExtractNoteModal && (
          <div className="modal-background">
            <ExtractModal
              item={extractNoteItem}
              onClose={() => {
                setExtractNoteItem("");
                setShowExtractNoteModal(false);
              }}
            />
          </div>
        )}
        <div className="flex-row" style={{ justifyContent: "space-between" }}>
          <div className="flex-row">
            {profile === profileENUM.ADMIN_BOSCH && (
              <ImportExportCsv
                exportApi="exportscrapbygeneratorscsv"
                importApi=""
                onClose={handleCloseModal}
              />
            )}
          </div>
          <FilterComponent
            data={tableData}
            onFilterClick={handleFilterClick}
            sortOptions={sortOption}
          />
        </div>
        <br />
        <br />
        {tableData.length === 0 && <NoData />}

        {tableData.length > 0 && (
          <>
            <div className="info-section">
              <SparkTable>
                <SparkTableHead>
                  <SparkTableRow>
                    {tableHeader.map((header, index) => (
                      <SparkTableCell key={index}>
                        <strong>{header.toUpperCase()}</strong>
                      </SparkTableCell>
                    ))}
                  </SparkTableRow>
                </SparkTableHead>
                <SparkTableBody>
                  {filteredData.map((rowItem: any, rowIndex: any) => (
                    <SparkTableRow
                      key={rowIndex}
                      onClick={() => {
                        setExtractNoteItem(rowItem);
                        setShowExtractNoteModal(true);
                      }}
                    >
                      <SparkTableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip
                            text={
                              rowItem.isHazardousMaterial
                                ? t("label-hazardous-material")
                                : t("label-non-hazardous-material")
                            }
                          >
                            {rowItem.isHazardousMaterial && (
                              <SparkIcon
                                className="icon-flag"
                                no-padding={true}
                                icName={"danger-hazards"}
                              />
                            )}
                            <p style={{ marginLeft: "24px" }}>
                              {rowItem.scrapMaterialName}
                            </p>
                          </Tooltip>
                        </div>
                      </SparkTableCell>
                      <SparkTableCell>
                        {rowItem.scrapGeneratorName}
                      </SparkTableCell>
                      <SparkTableCell>{rowItem.scrapDealerName}</SparkTableCell>
                      <SparkTableCell>
                        {receiveNumberFormatWithMilhar(rowItem.quantity)}
                      </SparkTableCell>
                      <SparkTableCell>
                        {rowItem.currency}{" "}
                        {
                          rowItem.totalValue ? receiveNumberFormatWithMilhar(
                            Number(rowItem.totalValue).toFixed(2)
                          ) : "-"
                        }
                      </SparkTableCell>

                      <SparkTableCell>
                        {formatTimestampDate(rowItem.dateToCollect)}
                      </SparkTableCell>
                    </SparkTableRow>
                  ))}
                </SparkTableBody>
              </SparkTable>
            </div>
          </>
        )}
      </div>
    );
  } else {
    navigate("/page-not-found");
    return <></>;
  }
};
export default FinancialToCollectView;
